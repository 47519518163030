<template>
  <header
    class="shadow-custom-1 sticky top-0 z-50 flex h-16 items-center bg-white bg-opacity-80 backdrop-blur-sm dark:bg-opacity-80">
    <div class="container">
      <div class="relative justify-between">
        <div class="w-40 max-w-full">
          <l-logo />
        </div>
        <div class="flex w-full items-center justify-end">
          <div class="absolute top-1/2 block -translate-y-1/2">
            <div class="flex gap-3">
              <l-button
                variant="primary"
                type="button"
                @click="navigateTo('https://app.teachmehipaa.com', { external: true })">
                Log in
              </l-button>
              <l-button variant="secondary" type="button" class="" @click="navigateTo('/signup')">
                Sign up
              </l-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup></script>
